import Rails from '@rails/ujs';

import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/600-italic.css';
import '@fontsource/source-sans-pro/700-italic.css';

import '../styles/devise.scss';
Rails.start();

require.context('../images', true);
